import React from "react";
import ReactDOM from "react-dom/client";
import {Navbar} from "./component/Header"
import Prices from "./component/Prices"
import {ImageFetcher} from "./temp";
import { Pricelist } from "./const";

import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import {Error} from "./component/Error";
import {Body} from "./component/Body"
import { PriceDetails } from "./component/PriceDetails";
import { AboutUs } from "./component/AboutUs";
import {Footer} from "./component/Footer"
import { ContactUs } from "./component/ContactUs";
export const AppLayout = () => {
  return (
    <div className="applayout">
      <Navbar/>
      <Outlet />
      <Footer/>
    </div>
  );
};

const Approuter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Body/>,
      },
      
      {
        path: "aboutUs",
        element: <AboutUs />,
      },
      {
        path: "contact",
        element: <ContactUs />,
      },
      {
        path: "prices",
        element:<Prices/>
      },
      {
        path: "temp",
        element: <ImageFetcher query="Netflix"/>
      },
      {
        path:"/prices/:object",
        element:<PriceDetails/>
      },{
        path:"/price/:cat",
        element:<Prices/>
      }
   
    ],
    errorElement: <Error />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={Approuter} />);
