  import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { ContactUs } from './ContactUs';

export const Navbar = () => {
 const [isNavbarOpen, setIsNavbarOpen] = useState(false);
 useEffect(() => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css';
  link.integrity = 'sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH';
  link.crossOrigin = 'anonymous';
  document.head.appendChild(link);
}, []);

 const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
 };

 return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        {/* Brand Logo */}
       <Link to="./"><a className="navbar-brand" >
          <img id="main_logo" src="https://i.postimg.cc/6Tv7VrbJ/logo.png" alt="Logo" />
        </a></Link> 
        {/* Toggler for mobile view */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={isNavbarOpen}
          aria-label="Toggle navigation"
          onClick={toggleNavbar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* Navigation Links */}
        <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <Link to="./" className='linkheader'>  <li className="nav-item">
              <a className="nav-link active" aria-current="page" >Home</a>
            </li></Link> 
            <Link to="/prices" className='linkheader'>  <li className="nav-item">
              <a className="nav-link ">Price List</a>
            </li></Link> 
            <li className="nav-item disabled linkheader">
              <a className="nav-link">Updates</a>
            </li>
          <Link to="/contact"> <li className="nav-item linkheader">
              <a className="nav-link">Contact us</a>
            </li> </Link> 
            <Link to="/aboutus" className='linkheader'>  <li className="nav-item">
              <a className="nav-link ">About Us</a>
            </li></Link> 
           
           
            
         </ul>
        </div>
      </div>
    </nav>
 );
};

export default Navbar;

    
 