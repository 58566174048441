import React from "react";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3>Contact Us</h3>
        <a href="https://wa.me/+9779800806223" target="_blank" className="linkheaderftcnt">
          <p>Phone: +977 9800806223</p>
        </a>
         <a href="mailto:Subscriptionnepal6636@gmail.com" className="linkheaderftcnt">   <p>Subscriptionnepal6636@gmail.com</p></a>
     
      </div>


      <div className="footer-section">
        <h3>Follow Us</h3>
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61557596682658">
            <img
              src="https://i.pinimg.com/474x/f7/99/20/f79920f4cb34986684e29df42ec0cebe.jpg"
              alt="https://w7.pngwing.com/pngs/637/497/png-transparent-facebook-fb-social-media-logo-social-media-logo-socialmedia-3d-icon-thumbnail.png"
            />
          </a>
          <a href="https://T.me/A_singh66">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/1200px-Telegram_2019_Logo.svg.png"
              alt="Telegram"
            />
          </a>
          <a href="https://whatsapp.com/channel/0029VaZB0GgCMY0OZH9EuY0j">
            <img src="https://static-00.iconduck.com/assets.00/whatsapp-icon-1024x1024-cilsjgvb.png"></img>
          </a>
        </div>
      </div>

      {/* <div className="footer-section">
        <h3>Subscribe to Our Newsletter</h3>
        <form action="/subscribe" method="post">
          <input type="email" name="email" placeholder="Your Email" />
          <input type="submit" value="Subscribe" />
        </form>
      </div> */}

      <div className="footer-section">
        <p>&copy; 2024 Subscription Nepal. All rights reserved.</p>
      </div>
    </footer>
  );
};
