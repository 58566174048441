export const carouselimg1 =
  "https://cdn.hobbyconsolas.com/sites/navi.axelspringer.es/public/media/image/2021/05/top-10-netflix-espana-2335737.jpg?tf=3840x";
export const carouselimg2 =
  "https://upload.wikimedia.org/wikipedia/en/3/3c/Netflix_UI_for_Web.png";
export const carouselimg3 =
  "https://i.postimg.cc/sX8gBjX8/SUSBCRIPTION-NEPAL.png";

  export const Pricelist = [
    {
      Name: "Netflix",
      info: "Netflix",
      imgid: "https://cdn.dribbble.com/users/9378043/screenshots/16832559/media/10b207c918d604662e088308d16b366d.png",
      category: "ott",
      plans: {
        "1": "270",
        3: "810",
        6: "1600",
      },
    },
    {
      Name: "Disney+Hotstar",
      info: "Disney+Hotstar",
      imgid: "https://yt3.googleusercontent.com/-i9vS-g8mUx5IL0tsprB9mLW4DMYzODoLdehGvjZBYzzHL42QAqHO_DQ7c6ieKY8z81JPl6f74E=s900-c-k-c0x00ffffff-no-rj",
      category: "ott",
      plans: {
        "6": "1350",
        "12": "2400",
      },
    },
    {
      Name: "Spotify Premium",
      info: "Spotify Premium",
      imgid: "https://www.bitrefill.com/primg/w350h212/spotify-uk.webp",
      category: "music",
      plans: {
        "1": "180",
        "6": "850",
        "12": "1500",
      },
    },
    {
      Name: "YouTube Premium",
      info: "YouTube Premium",
      imgid: "https://static.digit.in/default/new-youtube-premium-features-1280-6d58ec9cf9.png",
      category: "ott",
      plans: {
        "1": "250",
        "6": "900",
        "12": "1600",
      },
    },
    {
      Name: "Surfshark VPN",
      info: "Surfshark VPN",
      imgid: "https://www.01net.com/en/app/uploads/2022/08/Surfshark-Review.jpeg",
      category: "software",
      plans: {
        "6": "700",
        "12": "1050",
      },
    },
    {
      Name: "ULLU",
      info: "ULLU",
      imgid: "https://yt3.googleusercontent.com/ytc/AIdro_m9Vw_5Bn_NmKGfRhmjVRtkCl424EVMoEKUHbSFdA=s900-c-k-c0x00ffffff-no-rj",
      category: "ott",
      plans: {
        "1": "200",
        "3": "450",
        "6": "800",
        "12": "1000",
      },
    },
    {
      Name: "Brazzer",
      info: "Brazzer",
      imgid: "https://images.fineartamerica.com/images/artworkimages/mediumlarge/3/brazzers-logo-betran-firmanza.jpg",
      category: "18+",
      plans: {
        "1": "350",
        "3": "900",
      },
    },
    {
      Name: "Pornhub Premium",
      info: "Pornhub Premium",
      imgid: "https://is.zobj.net/image-server/v1/images?r=82U_12Cz0-c1mVwRTWdglB_Di6ZwJACjx8IEC8CRIlYsirvL1_FQayaEkubtASR4G9r_x6QEaBQy8fMMQR9QYZx1BUctNBPilLYh6WEckIQCQcnyzsoRbG2OhVIbODN9sctVslJT3X02IjpR2mXraVCi5jIKgUCgaTSmI3BVTeKweZtt1AIwlNeW1SX51ZFWJBeOoKAavSFK-9dhRPMHLxe5URt-RRtk8SNn0g",
      category: "18+",
      plans: {
        "1": "350",
        "3": "900",
      },
    },
    {
      Name: "Reality King",
      info: "Reality King",
      imgid: "https://assets.change.org/photos/1/rn/kl/INRnkLFrpDWmVyt-800x450-noPad.jpg?1583109162",
      category: "ott",
      plans: {
        "1": "350",
        "3": "900",
      },
    },
    {
      Name: "Nord VPN",
      info: "Nord VPN",
      imgid: "https://img.securityinfowatch.com/files/base/cygnus/siw/image/2023/09/16x9/NordVPN_logo.6500c0f150a53.png?auto=format,compress&fit=crop&q=45&h=667&w=1200",
      category: "software",
      plans: {
        "3": "400",
        "6": "600",
        "12": "900",
      },
    },
    {
      Name: "Alt Balaji",
      info: "Alt Balaji",
      imgid: "https://upload.wikimedia.org/wikipedia/en/thumb/8/86/Altbalaji_Logo.svg/1200px-Altbalaji_Logo.svg.png",
      category: "ott",
      plans: {
        "1": "180",
        "3": "450",
        "6": "650",
        "12": "1000",
      },
    },
    {
      Name: "Ganna Plus",
      info: "Ganna Plus",
      imgid: "https://images.yourstory.com/cs/images/companies/CLOGOS23-16-1588054358977.png?fm=auto&ar=1:1&mode=fill&fill=solid&fill-color=fff",
      category: "music",
      plans: {
        "1": "240",
        "6": "680",
        "12": "950",
      },
    },
    {
      Name: "Hulu",
      info: "Hulu",
      imgid: "https://i.ytimg.com/vi/wgcvJG9K4r0/maxresdefault.jpg",
      category: "ott",
      plans: {
        "1": "290",
        "3": "800",
      },
    },
    {
      Name: "Crunchyroll",
      info: "Crunchyroll",
      imgid: "https://1000logos.net/wp-content/uploads/2021/05/Crunchyroll-logo.png",
      category: "ott",
      plans: {
        "1": "240",
        "3": "600",
        "12": "1000",
      },
    },
    {
      Name: "Kuku FM",
      info: "Kuku FM",
      imgid: "https://www.medianews4u.com/wp-content/uploads/2022/06/Kuku-FM-crosses-1-mn-active-paying-subscribers-in-India.jpg",
      category: "music",
      plans: {
        "12": "800",
      },
    },
    {
      Name: "DangalPlay",
      info: "DangalPlay",
      imgid: "https://play-lh.googleusercontent.com/voE69ACxF0WdwvbKAMQpVkhwGcDm9iA9S4mArxo96LVnkX_-3AJLGAtGWBElbgUoyg",
      category: "ott",
      plans: {
        "3": "499",
        "12": "1200",
      },
    },
    {
      Name: "Prime Video",
      info: "Prime Video",
      imgid: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Amazon_Prime_Video_blue_logo_1.svg/1200px-Amazon_Prime_Video_blue_logo_1.svg.png",
      category: "ott",
      plans: {
        "1": "140",
        "3": "299",
        "6": "550",
        "12": "950",
      },
    },
    {
      Name: "Express VPN",
      info: "Express VPN",
      imgid: "https://www.cnet.com/a/img/resize/b1cb20603ffb29a2501262328b981eabff61fb4f/hub/2021/01/19/6525e82f-c20c-477d-b8c4-26d5f06be6aa/expressvpn-vertical-logo-white-on-red.png?auto=webp&fit=crop&height=675&width=1200",
      category: "software",
      plans: {
        "12": "1250",
      },
    },
    {
      Name: "Canava Pro",
      info: "Canava Pro",
      imgid: "https://i.pinimg.com/474x/6c/d7/20/6cd7205407c8d4a5a7c4d5edad97cda5.jpg",
      category: "sofware",
      plans: {
        "12": "250",
      },
    },
    {
      Name: "ZEE5",
      info: "ZEE5",
      imgid: "https://www.adgully.com/img/800/201906/zee5.jpg",
      category: "ott",
      plans: {
        "1": "250",
        "3": "500",
        "6": "850",
        "12": "1000",
      },
    },
    {
      Name: "JioCinema Premium",
      info: "JioCinema Premium",
      imgid: "https://v3img.voot.com/v3Storage/assets/jc_logo_stack-1682163295957.jpg",
      category: "ott",
      plans: {
        "6": "500",
        "12": "850",
      },
    },
    {
      Name: "IDM",
      info: "IDM",
      imgid: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3eZG11q5NzSjezSmb5AF7N7VCzVJm_I_H78X2M4andw&s",
      category: "software",
      plans: {
        "1": "NPR 2250",
        "12": "NPR 4599",
      },
    },
    {
      Name: "Facebook Services",
      info: "Facebook Services",
      imgid: "https://store-images.s-microsoft.com/image/apps.37935.9007199266245907.b029bd80-381a-4869-854f-bac6f359c5c9.91f8693c-c75b-4050-a796-63e1314d18c9?h=464",
      plans: {},
    },
    {
      Name: "Instagram Services",
      info: "Instagram Services",
      imgid: "https://img.freepik.com/free-vector/instagram-logo_1199-122.jpg",
      category: "software",
      plans: {
        "1": "NPR 2250",
        "12": "NPR 4599",
      },
    },
    {
      Name: "Youtube Services",
      info: "Youtube Services",
      imgid: "https://feeds.abplive.com/onecms/images/uploaded-images/2022/01/31/0dfe05d1f843d2705c096b93ccb80e54_original.jpg?impolicy=abp_cdn&imwidth=720",
      category: "software",
      plans: {
        "1": "NPR 2250",
        "12": "NPR 4599",
      },
    },
  ];
  
export const Category = [
    {
        name:"ott",
        imgurl:"https://i.postimg.cc/V6XN2y4b/movies-logo.png"
    },
    {
        name:"music",
        imgurl:"https://i.postimg.cc/ZnDxgywR/music-logo.png",
    },{
        name:"18+",
        imgurl:"https://i.postimg.cc/3NfrMKM9/18-logo.png"
    },
    {
      name:"software",
      imgurl:"https://i.postimg.cc/brqxd9Bd/software-Logo.png"
  }
]