import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Pricelist } from "../const";

export const PriceDetails = () => {
  const [Objectdetails, setObjectdetails] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("1");
  const { object } = useParams();

  useEffect(() => {
    const alpha = Pricelist.find((item) => item.Name === object);
    setObjectdetails(alpha);
    const firstMonth = Object.values(alpha.plans)[0];
    setSelectedMonth(firstMonth);
  }, [object]);

  // Function to handle click on month button
  const handleMonthClick = (month) => {
    setSelectedMonth(month);
  };

  // Function to handle change in device radio
  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  if (!Objectdetails) {
    return <div>Loading...</div>;
  }

  const totalCost = parseInt(selectedMonth) * parseInt(selectedDevice);

  return (
    <div className="PriceLoneContainer">
      <div className="objimgcont">
        <img
          className="objimg"
          src={Objectdetails.imgid}
          alt={Objectdetails.Name}
        />
      </div>
      <div className="objdetils">
        <h1>{Objectdetails.Name}</h1>
        <h4>Number of months</h4>
        <ul className="monthul">
          {Object.entries(Objectdetails.plans).map(([key, value]) => (
            <button
              key={key}
              className={`monthnumber ${
                selectedMonth === key ? "selected" : ""
              }`}
              type="button"
              onClick={() => handleMonthClick(value)}
            >
              {key}
            </button>
          ))}
        </ul>
        <h4>Number of devices</h4>
        <div className="radioclass">
          <input
            type="radio"
            id="radio1"
            className="radios"
            name="radioGroup"
            value="1"
            checked={selectedDevice === "1"}
            onChange={handleDeviceChange}
          />
          <label htmlFor="radio1">1</label>

          <input
            type="radio"
            id="radio2"
            className="radios"
            name="radioGroup"
            value="2"
            checked={selectedDevice === "2"}
            onChange={handleDeviceChange}
          />
          <label htmlFor="radio2">2</label>

          <input
            type="radio"
            id="radio3"
            className="radios"
            name="radioGroup"
            value="3"
            checked={selectedDevice === "3"}
            onChange={handleDeviceChange}
          />
          <label htmlFor="radio3">3</label>
        </div>
        <h6>Total cost: {totalCost}</h6>
      </div>
    </div>
  );
};
