import { useRouteError } from "react-router-dom";
import {AppLayout} from "../app"
 export const Error = () => {
  const err = useRouteError();
  console.log(err);
  return (
    <div>
    <AppLayout />
    <h4>You got error {(err.data)}</h4>
    <h3>Error Message{(err.error.message)}</h3>
    <h3>Error Status is {(err.status)}</h3>

  </div>
  );
};
