 export const AboutUs = () =>{
    return(
        <div style={{margin:"50px"}}>

Welcome to our platform, where we proudly provide subscription services for various platforms at unbeatable rates. Elevate your digital experience with us, as we not only offer cost-effective subscriptions but also an array of enticing digital products and convenient gift cards.
<br></br><br></br>
With over five years of dedicated service, we have established ourselves as a reliable and trustworthy destination for all your digital needs. Our commitment to customer satisfaction is unwavering, ensuring you a hassle-free experience with every purchase.
<br></br>
Despite our small but dedicated team, we prioritize your needs. While we aim to respond promptly, there might be occasional delays due to our limited staff. Your patience is appreciated, and rest assured, your inquiry will be attended to with the utmost care.

Explore our platform, embrace affordability, and indulge in a seamless digital shopping experience. Join the community that has trusted us for over five years, and let us enhance your digital lifestyle today!
<br></br><br></br>
Most of our customers are happily engaged, with over 7,000 satisfied users. We pride ourselves on our 4.8-star rating on Facebook, reflecting the trust and satisfaction of our community. Dedicated to providing OTT services in Nepal and other countries at cheaper rates, we continuously strive to exceed expectations.
<br></br><br></br>  <ul>  <li> **Unbeatable Rates**: We provide subscription services for various platforms at unbeatable rates.
</li><li> **Trustworthy**: With over five years of dedicated service, we've established ourselves as a reliable destination for all your digital needs.
</li><li>**Customer Satisfaction**: Our commitment to customer satisfaction is unwavering, ensuring a hassle-free experience with every purchase.
</li><li>**Community Trust**: With over 7,000 satisfied customers and a 4.8-star rating on Facebook, our community trusts us for their digital needs.
</li><li>**Global Reach**: We offer OTT services not only in Nepal but also in other countries, providing affordable rates to enhance your digital lifestyle.
</li></ul> 
        </div>
    )
  }