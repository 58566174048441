import React, { useState, useEffect } from 'react';

export const ImageFetcher = ({ query }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Define fetchImageUrl function inside the useEffect hook
    const fetchImageUrl = async (query) => {
      const apiKey = 'jbs6MVmkjWL2CpvNRWa5Mn2Sn8Mm_VSfVJg33mrHqV8'; 
      const url = `https://api.unsplash.com/search/photos?query=${query}&client_id=${apiKey}&per_page=1`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.results && data.results.length > 0) {
          setImageUrl(data.results[0].urls.small);
          console.log(imageUrl); // Corrected from ImageUrl to imageUrl
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImageUrl(query);
  }, [query]);

  return (
    <div>
      {imageUrl ? (
        <img className='pirceimg' src={imageUrl} alt={query} />
      ) : (
        <p>Loading image...</p>
      )}
    </div>
  );
};

export default ImageFetcher;
