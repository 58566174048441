import React from 'react';
import { Category } from "../const";
import {Link} from "react-router-dom"
export const Cards = () => {
 return (
    <div className="categorycontainer">
      {Category.map((category, index) => (
         <Link to={"/price/" + category.name}key={index + 1} className='linkheader'>
        <div className="Cardcontainer" key={index + 1}>
          <img src={category.imgurl} className="categroyimage" alt="not loading" />
          <h5 className="categoryname">{category.name}</h5>
        </div> </Link>)
      )}
    </div>
 );
};
