import React from "react";
import { useEffect } from "react";
import { carouselimg1, carouselimg2, carouselimg3 } from "../const";
import "./body.css";
import { Cards } from "./Cards";
import ott from "../images/ott.png";
import music from "../images/music.png";

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export const Carousell = () => {
  return (
    <div id="carousell">
      <div
        id="demo"
        className="carousel slide"
        data-bs-ride="carousel"
        style={{ height: "200px" }}
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#demo"
            data-bs-slide-to="0"
            className="active caroselind"
           
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            className="caroselind"
            data-bs-target="#demo"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            className="caroselind"
            data-bs-target="#demo"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>

        {/* The slideshow/carousel */}
        <div className="carousel-inner" style={{ height: "550px" }}>
          <div className="carousel-item active" data-bs-interval="3000">
            <img src={ott} className="d-block w-100" alt="Los Angeles" />
          </div>
          <div
            className="carousel-item"
            data-bs-interval="2000"
            style={{ height: "20px" }}
          >
            <img src={music} className="d-block w-100" alt="Chicago" />
          </div>
          <div className="carousel-item" style={{ height: "20px" }}>
            <img src={carouselimg3} className="d-block w-100" alt="New York" />
          </div>
        </div>

        {/* Left and right controls/icons */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#demo"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#demo"
          data-bs-slide="next"
          style={{ margin: "40px" }}
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <h4 id="categoory">Category</h4>
    </div>
  );
};

export const Body = () => {
  return (
    <div id="caroselandcardcontainer">
    
      <Carousell />
      <Cards />
    </div>
  );
};
