import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pricelist } from "../const";
import { useParams } from "react-router-dom";

export const Prices = () => {
  let [searchvalue, setsearchvalue] = useState("");
  let [Pricelistcopy, setPricelistcopy] = useState(Pricelist);
  const { cat } = useParams();

  useEffect(() => {
    if (cat === undefined) {
      console.log("not category");
    } else {
      let searchedlist = Pricelist.filter((res) => res.category === cat);
      setPricelistcopy(searchedlist);
    }
  }, [cat]); 

  const searchlist = () => {
    let searchedlist = Pricelist.filter(
      (res) =>
        res.Name && res.Name.toLowerCase().includes(searchvalue.toLowerCase())
    );
    if (searchedlist.length === 0) {
      setPricelistcopy(Pricelist);
    } else {
      setPricelistcopy(searchedlist);
    }
  };

  return (
    <div className="mainpriceconainter">
      <div className="filterclass">
        <input
          type="text"
          className="searchtext"
          value={searchvalue}
          onChange={(e) => {
            // Update searchvalue before calling searchlist
            setsearchvalue(e.target.value);
            setTimeout(searchlist, 4000);
          }}
          placeholder="Enter text"
        ></input>
        <button onClick={searchlist}>Search</button>
      </div>
      <div id="pricecontainer">
        {Pricelistcopy.map((Pricelist, index) => {
          return (
            <Link to={"/prices/" + Pricelist.Name} key={index + 1} className='linkheader'>
              <div className="pricecontainer">
                <img
                  src={Pricelist.imgid}
                  alt="url not loaded"
                  className="pricelistimage"
                ></img>
                <h6 className="priceinfo">{Pricelist.info}</h6>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Prices;
